import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ArticulosService } from "app/services/articulos.service";
import { CategoriasArticulosService } from "app/services/categorias-articulos.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { CreateArticuloGeneralComponent } from "./components/create-articulo-general/create-articulo-general.component";
import { CreateArticuloVariantesComponent } from "./components/create-articulo-variantes/create-articulo-variantes.component";
import { CreateArticuloImagesComponent } from "./components/create-articulo-images/create-articulo-images.component";
import { cloneDeep } from "lodash";

export const CREATE_ARTICULO_MODAL_ID = "CREATE_ARTICULO_MODAL";

export enum Tallas {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export enum Paises {
  us = "Estados Unidos",
  mx = "México",
}

export interface CreateArticuloData {
  id?: number;
}

@Component({
  selector: "app-create-articulo",
  templateUrl: "./create-articulo.component.html",
  styleUrls: ["./create-articulo.component.css"],
})
export class CreateArticuloComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly onDestroy$ = new Subject<void>();

  @ViewChild(CreateArticuloGeneralComponent)
  general: CreateArticuloGeneralComponent;

  @ViewChild(CreateArticuloVariantesComponent)
  variantes: CreateArticuloVariantesComponent;

  @ViewChild(CreateArticuloImagesComponent)
  imagenes: CreateArticuloImagesComponent;

  public sections = ["general", "variantes", "imágenes"];
  public section = "general";

  private data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._articulos.getById(this.data.id)),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (
        this.general.form.invalid ||
        this.general.form.get("colores").invalid
      ) {
        this.markFormGroupTouched(this.general.form);
        throw this.general.form.get("colores").invalid
          ? "Error en los colores"
          : "Error en el formulario.";
      }
      const value = this.general.form.value;
      if (value.colores && value.colores.length)
        value.colores = value.colores.map(({ nombre, color }) => ({
          nombre,
          color,
        }));
      return this._articulos.create(this.general.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (
        this.general.form.invalid ||
        this.general.form.get("colores").invalid
      ) {
        this.markFormGroupTouched(this.general.form);
        throw this.general.form.get("colores").invalid
          ? "Error en los colores"
          : "Error en el formulario.";
      }
      const value = this.general.form.getRawValue();
      if (value.colores && value.colores.length)
        value.colores = value.colores.map(({ nombre, color, id }) => ({
          id,
          nombre,
          color,
        }));
      return this._articulos.update(this.data.id, value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  //data
  public data: CreateArticuloData;
  public articulo: any;
  public categorias: any[];

  //Status
  public loading: boolean;
  public requesting: boolean;
  public created: boolean;

  constructor(
    private _articulos: ArticulosService,
    private _categoriasArticulos: CategoriasArticulosService,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager
  ) {}

  ngOnInit() {
    this.getData();
    this.getCategorias();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    this.data = this._modal.get(CREATE_ARTICULO_MODAL_ID).getData();
    if (!this.data.id) return;
    this.data$.subscribe(
      (data) => {
        this.articulo = data;
      },
      () =>
        this._toast.errorToastr(
          "Error al obtener artículo, verifica tu conexión a internet."
        )
    );
  }

  getCategorias() {
    this._categoriasArticulos
      .get()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([data]) => (this.categorias = data));
  }

  onUpdate() {
    this.getData();
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this._toast.successToastr("Artículo agregado correctamente.");
        this._modal
          .get(CREATE_ARTICULO_MODAL_ID)
          .removeData()
          .setData({ id: data.id });
        this.getData();
        this.created = true;
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    console.log(cloneDeep(this.general.form));
    this.update$.subscribe(
      (data) => {
        this._toast.successToastr("Artículo editado correctamente.");
        this.getData();
        this.created = true;
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(success?: boolean) {
    this._modal
      .get(CREATE_ARTICULO_MODAL_ID)
      .removeData()
      .setData({ success, created: this.created })
      .close();
    this._modal.removeModal(CREATE_ARTICULO_MODAL_ID);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}

import { Component } from "@angular/core";

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
  description?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
  description?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    description: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-bank",
  },
  {
    path: "/ordenes",
    title: "Ordenes",
    description: "Ordenes",
    type: "link",
    // ** NOTA: este atributo children SOLO ESTÁ AQUI para poder mostrar el title de la ruta en el navbar,
    //no sirve para otra cosa, por eso no tiene icono ni nada
    children: [
      {
        path: "nueva",
        icontype: "",
        title: "Nueva orden de producción",
        description: "Nueva orden de producción",
      },
      {
        path: "editar",
        icontype: "",
        title: "Editar orden de producción",
        description: "Editar orden de producción",
      },
      {
        path: "detalle",
        icontype: "",
        title: "Detalle orden de producción",
        description: "Detalle orden de producción",
      },
    ],
    icontype: "fal fa-baseball",
    permission: "ORDENES",
  },
  {
    path: "/bitacora-inventario",
    title: "Bitácora de inventario",
    description: "Bitácora de inventario de billets y bats",
    type: "link",
    icontype: "fal fa-clipboard-list",
    permission: "BITACORA DE INVENTARIO",
  },
  {
    path: "/bitacora-produccion",
    title: "Bitácora de producción",
    description: "Bitácora de producción de bats",
    type: "link",
    icontype: "fal fa-scanner",
    permission: "BITACORA DE PRODUCCION",
  },
  {
    path: "/inventario-detalle",
    title: "Detalle de inventario",
    description: "Detalle de inventario de billets y bats",
    type: "link",
    icontype: "fal fa-info-square",
    permission: "DETALLE DE INVENTARIO",
  },
  {
    path: "/produccion-dia",
    title: "Producción",
    description: "Producción del dia",
    type: "link",
    icontype: "fal fa-scroll-old",
    children: [
      {
        path: "generar",
        icontype: "",
        title: "Generar orden de producción",
        description: "Generar orden de producción",
      },
    ],
    permission: "PRODUCCION DEL DIA",
  },
  {
    path: "/facturas",
    title: "Facturas",
    icontype: "fas fa-hand-holding-usd",
    type: "link",
    permission: "FACTURAS",
    description: "Facturas",
  },
  {
    path: "/suscripciones",
    title: "Suscripciones",
    icontype: "fas fa-hand-holding-usd",
    type: "link",
    permission: "SUSCRIPCIONES",
    description: "Suscripciones",
  },
  {
    path: "/ecommerce",
    title: "Ecommerce",
    description: "Ecommerce",
    type: "sub",
    icontype: "fal fa-store",
    children: [
      {
        path: "bats-disponibles",
        title: "Bats disponibles",
        icontype: "fal fa-baseball",
        permission: "ECOMMERCE",
        description: "Bats disponibles para ecommerce",
      },
      {
        path: "ventas",
        title: "Ventas",
        icontype: "fal fa-shopping-cart",
        permission: "ECOMMERCE",
        description: "Ventas de ecommerce",
      },
      // ** NOTA: este atributo children SOLO ESTÁ AQUI para poder mostrar el title de la ruta en el navbar,
      //no sirve para otra cosa, por eso no tiene icono ni nada
      {
        path: "detalle",
        icontype: "",
        title: "Detalle de venta",
        description: "Detalle de venta",
      },
    ],
  },

  // {
  //   path: '/ecommerce',
  //   title: 'Ecommerce',
  //   description: 'Bats para ecommerce',
  //   type: 'link',
  //   icontype: 'fal fa-store',
  //   permission: "ECOMMERCE"
  // },

  {
    path: "/inventario-productos",
    title: "Productos",
    description: "Productos",
    type: "sub",
    icontype: "fal fa-cubes",
    children: [
      {
        path: "bitacora-productos",
        title: "Bitácora de movimientos",
        icontype: "fal fa-clipboard-list",
        permission: "INVENTARIO DE PRODUCTOS",
        description: "Bitácora de movimientos de productos",
      },
      {
        path: "totales-productos",
        title: "Totales por almacén",
        icontype: "fal fa-list-ol",
        permission: "INVENTARIO DE PRODUCTOS",
        description: "Totales de productos por almacén",
      },
    ],
  },
  {
    path: "/reportes",
    title: "reportes",
    description: "Reportes",
    type: "sub",
    icontype: "fal fa-user-chart",
    children: [
      {
        path: "totales",
        title: "Totales por almacén",
        icontype: "fal fa-list-ol",
        permission: "TOTALES POR ALMACEN",
        description: "Totales de billets ó bats por almacén",
      },
      {
        path: "peso-modelo",
        title: "Peso por modelo",
        icontype: "fal fa-list-ol",
        permission: "REPORTE PESOS POR MODELO",
        description: "Promedio de peso por modelo",
      },
    ],
  },
  {
    path: "/catalogos",
    title: "catálogos",
    type: "sub",
    icontype: "fal fa-archive",
    children: [
      //<i class="fal fa-user-check"></i>
      {
        path: "agentes",
        title: "Agentes",
        icontype: "fal fa-warehouse-alt",
        permission: "AGENTES",
        description: "Catálogo de agentes",
      },
      {
        path: "almacenes",
        title: "Almacenes",
        icontype: "fal fa-warehouse-alt",
        permission: "ALMACENES",
        description: "Catálogo de almacenes",
      },
      {
        path: "articulos",
        title: "Artículos",
        icontype: "fal fa-cubes",
        permission: "ARTICULOS",
        description: "Catálogo de artículos",
      },
      {
        path: "categorias-articulos",
        title: "Categoría de artículos",
        icontype: "fal fa-project-diagram",
        permission: "CATEGORIA DE ARTICULOS",
        description: "Catálogo de categorías de artículos",
      },
      {
        path: "categorias-productos",
        title: "Categoría de productos",
        icontype: "fal fa-project-diagram",
        permission: "CATEGORIA DE PRODUCTOS",
        description: "Catálogo de categorías de productos",
      },
      {
        path: "clientes",
        title: "Clientes",
        icontype: "fal fa-address-card",
        permission: "CLIENTES",
        description: "Catálogo de clientes",
      },
      {
        path: "colores",
        title: "Colores",
        icontype: "fal fa-palette",
        permission: "COLORES",
        description: "Catálogo de colores",
      },
      {
        path: "conceptos",
        title: "Conceptos",
        icontype: "fas fa-hand-holding-usd",
        permission: "CONCEPTOS",
        description: "Catálogo de conceptos",
      },
      {
        path: "coordinadores",
        title: "Coordinadores",
        icontype: "fal fa-person-carry",
        permission: "COORDINADORES",
        description: "Catálogo de coordinadores",
      },
      {
        path: "descuentos",
        title: "Descuentos",
        icontype: "fal fa-percent",
        permission: "DESCUENTOS",
        description: "Descuentos",
      },
      {
        path: "distribuidores",
        title: "Distribuidores",
        icontype: "fal fa-warehouse-alt",
        permission: "DISTRIBUIDORES",
        description: "Catálogo de distribuidores",
      },
      {
        path: "equipos",
        title: "Equipos",
        icontype: "fal fa-user-friends",
        permission: "EQUIPOS",
        description: "Catálogo de equipos",
      },
      {
        path: "jugadores",
        title: "Jugadores",
        icontype: "fal fal fa-users",
        permission: "JUGADORES",
        description: "Catálogo de jugadores",
      },
      {
        path: "ligas",
        title: "Ligas de beisbol",
        icontype: "fal fa-baseball-ball",
        permission: "LIGAS DE BEISBOL",
        description: "Catálogo de ligas de beisbol",
      },
      {
        path: "lineas",
        title: "Líneas",
        icontype: "fal fa-clipboard-list",
        permission: "LINEAS",
        description: "Líneas",
      },
      {
        path: "maderas",
        title: "Maderas",
        icontype: "fal fa-tree",
        permission: "MADERAS",
        description: "Catálogo de maderas",
      },
      {
        path: "modelos",
        title: "Modelos",
        icontype: "fal fa-tags",
        permission: "MODELOS",
        description: "Catálogo de modelos",
      },
      {
        path: "pallets",
        title: "Pallets",
        icontype: "far fa-cubes",
        permission: "PALLETS",
        description: "Catálogo de pallets",
      },
      {
        path: "productos",
        title: "Productos",
        icontype: "far fa-cubes",
        permission: "PRODUCTOS",
        description: "Catálogo de productos",
      },
      {
        path: "proveedores",
        title: "Proveedores",
        icontype: "fal fa-truck-loading",
        permission: "PROVEEDORES",
        description: "Catálogo de proveedores",
      },
      {
        path: "representantes",
        title: "Representantes",
        icontype: "fal fa-user-check",
        permission: "REPRESENTANTES",
        description: "Catálogo de representantes",
      },
      {
        path: "stickers",
        title: "Stickers",
        icontype: "fal fa-user-check",
        permission: "STICKERS",
        description: "Catálogo de stickers",
      },
      {
        path: "tipos-clientes",
        title: "Tipos de clientes",
        icontype: "fal fa-poll-people",
        permission: "TIPOS DE CLIENTES",
        description: "Catálogo de tipos de clientes",
      },
      {
        path: "usuarios",
        title: "Usuarios",
        icontype: "fal fa-user-circle",
        permission: "USUARIOS",
        description: "Catálogo de usuarios",
      },
    ],
  },
  {
    path: "/configuracion",
    title: "configuración",
    description: "Configuración del sistema",
    type: "sub",
    icontype: "fal fa-cog",
    children: [
      {
        path: "configuracion-produccion",
        title: "Config. producción",
        icontype: "fal fa-person-dolly",
        permission: "CONFIGURACION DE PRODUCCION",
        description: "Configuración de producción",
      },
    ],
  },
];
@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem("jwtToken"));
  public perm = this.token.user.permisos;

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateArticuloComponent } from "./create-articulo.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal";
import { QuillModule } from "ngx-quill";
import { NgSelectModule } from "@ng-select/ng-select";
import { CreateArticuloGeneralComponent } from "./components/create-articulo-general/create-articulo-general.component";
import { CreateArticuloVariantesComponent } from "./components/create-articulo-variantes/create-articulo-variantes.component";
import { CreateArticuloImagesComponent } from "./components/create-articulo-images/create-articulo-images.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { CreateVarianteComponent } from "../create-variante/create-variante.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    QuillModule.forRoot(),
    NgSelectModule,
    NgxDatatableModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
  ],
  declarations: [
    CreateArticuloComponent,
    CreateArticuloGeneralComponent,
    CreateArticuloVariantesComponent,
    CreateArticuloImagesComponent,
    CreateVarianteComponent,
  ],
  exports: [CreateArticuloComponent, CreateVarianteComponent],
})
export class CreateArticuloModule {}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuariosService } from './services/usuarios.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private usuariosService: UsuariosService, private router: Router){

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // return this.usuariosService.isLoggedIn(); // antes solo era esta linea, ahora si no esta el token mandamos al login
    if (this.usuariosService.isLoggedIn()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login
    this.router.navigate(['/login']);
    return false;
  }
}

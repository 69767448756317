import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreatePalletComponent } from "./create-pallet.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ng6-toastr-notifications";

@NgModule({
  declarations: [CreatePalletComponent],
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    ReactiveFormsModule,
    NgSelectModule,
    NgxSmartModalModule,
    ToastrModule,
  ],
})
export class CreatePalletModule {}

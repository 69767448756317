import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class PalletsService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getAll(opts?: { page: number; limit?: number; search?: string }) {
    return this.http.get<[any[], number]>(`${this.globals.apiUrl}/pallets`, {
      params: <any>opts,
    });
  }

  getById(id: number) {
    return this.http.get<any>(`${this.globals.apiUrl}/pallets/${id}`);
  }

  create(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/pallets`, data);
  }

  update(id: number, data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/pallets/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.globals.apiUrl}/pallets/${id}`);
  }
}

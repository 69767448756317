import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  apiUrl: string = 'https://app.overflysports.com';

  formatMadera(madera) {
    let maderaSplit = madera.split(' ');
    switch(maderaSplit.length) {
      case 1:
        return `${maderaSplit[0][0].toUpperCase()}`;
      case 2:
        return `${maderaSplit[0][0].toUpperCase()}-${maderaSplit[1].toUpperCase()}`;
      case 3:
        return `${maderaSplit[0][0].toUpperCase()}-${maderaSplit[1].toUpperCase()}-${maderaSplit[2].toUpperCase()}`;
      default:
        return '';
    }
  }
}

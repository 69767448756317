import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Tallas } from "../../create-articulo.component";
import {
  CREATE_VARIANTE_MODAL_ID,
  CreateVarianteComponent,
} from "../../../create-variante/create-variante.component";
import { ToastrManager } from "ng6-toastr-notifications";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import {
  CONFIRMATION_MODAL_ID,
  ConfirmationModalComponent,
  ConfirmationModalData,
} from "app/shared/modals/confirmation-modal/confirmation-modal.component";
import { VariantesService } from "app/services/variantes.service";

@Component({
  selector: "create-articulo-variantes",
  templateUrl: "./create-articulo-variantes.component.html",
  styleUrls: ["./create-articulo-variantes.component.css"],
})
export class CreateArticuloVariantesComponent implements OnInit, OnDestroy {
  private update$ = (id: number, data) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        return this._variantes.update(id, data);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  private readonly onDestroy$ = new Subject<void>();
  @Input() set articulo(articulo) {
    this.variantes = [...(articulo ? articulo.variantes : [])];
  }
  @Output() onUpdate = new EventEmitter<boolean>();

  public variantes: any[] = [];

  public requesting: boolean;

  public tallas = Object.keys(Tallas).map((key) => ({
    key,
    value: Tallas[key],
  }));

  constructor(
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _variantes: VariantesService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    this.onUpdate.emit(true);
  }

  getTalla(talla: Tallas) {
    return this.tallas.find(({ value }) => value === talla) || {};
  }

  //Modals
  openCreate(id?: number) {
    this._modal
      .create(CREATE_VARIANTE_MODAL_ID, CreateVarianteComponent, {
        dismissable: false,
        closable: false,
      })
      .removeData()
      .setData({ id })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data: { success } }) => {
        if (!success) return;
        this.getData();
      });
  }

  openEdit(id: number) {
    if (!id) {
      this._toast.errorToastr("Selecciona una variante para editarla.");
      return;
    }
    this.openCreate(id);
  }

  openChangeStatus(data) {
    if (!data) {
      this._toast.errorToastr(
        "Selecciona una variante para cambiar su status."
      );
      return;
    }
    const action = data.status === "activo" ? "Desactivar" : "Activar";
    this._modal
      .create(CONFIRMATION_MODAL_ID, ConfirmationModalComponent, {
        dismissable: false,
        closable: false,
      })
      .setData(<ConfirmationModalData>{
        title: `${action} categoría de artículos`,
        message: `¿Estás seguro de ${action} la categoría`,
        strongMessage: `${
          data.color && data.color.nombre ? data.color.nombre : ""
        } ${data.talla || ""}?`,
        buttons: {
          confirm: {
            color: data.status === "activo" ? "danger" : "success",
            text: data.status === "activo" ? "DESACTIVAR" : "ACTIVAR",
          },
        },
      })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data: { confirmed } }) => {
        if (!confirmed) return;
        this.changeStatus(data.id, {
          status: data.status === "activo" ? "inactivo" : "activo",
        });
      });
  }

  changeStatus(id: number, data) {
    this.update$(id, data).subscribe(
      () => {
        this._toast.successToastr("Categoría eliminada correctamente.");
        this.getData();
      },
      (error) =>
        this._toast.errorToastr(
          "Error al eliminar categoría, intenta nuevamente."
        )
    );
  }
}

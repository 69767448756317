import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CategoriasArticulosService } from "app/services/categorias-articulos.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { of } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";

export const CREATE_CATEGORIA_ARTICULO_MODAL_ID =
  "CREATE_CATEGORIA_ARTICULO_MODAL";

export interface CreateCategoriaArticuloData {
  id?: number;
}
@Component({
  selector: "app-create-categoria-articulo",
  templateUrl: "./create-categoria-articulo.component.html",
  styleUrls: ["./create-categoria-articulo.component.css"],
})
export class CreateCategoriaArticuloComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._categoriasArticulos.getById(this.data.id)),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Error en el formulario.";
      return this._categoriasArticulos.create(this.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Error en el formulario.";
      return this._categoriasArticulos.update(this.data.id, this.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  public data: CreateCategoriaArticuloData;

  //Status
  public loading: boolean;
  public requesting: boolean;

  //Forms
  public form: FormGroup;

  constructor(
    private _categoriasArticulos: CategoriasArticulosService,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this.fb.group({
      nombre: ["", [Validators.required]],
      en: ["", [Validators.required]],
      es: ["", [Validators.required]],
      descripcion: [""],
    });
  }

  getData() {
    this.data = this._modal.get(CREATE_CATEGORIA_ARTICULO_MODAL_ID).getData();
    if (!this.data.id) return;
    this.data$.subscribe(
      (data) => {
        this.form.patchValue(data);
      },
      () =>
        this._toast.errorToastr(
          "Error al obtener categorías de artículo, verifica tu conexión a internet."
        )
    );
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this._toast.successToastr(
          "Categoría de artículo agregada correctamente."
        );
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    this.update$.subscribe(
      (data) => {
        this._toast.successToastr(
          "Categoría de artículo editada correctamente."
        );
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(success?: boolean) {
    this._modal
      .get(CREATE_CATEGORIA_ARTICULO_MODAL_ID)
      .removeData()
      .setData({ success })
      .close();
    this._modal.removeModal(CREATE_CATEGORIA_ARTICULO_MODAL_ID);
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).touched && this.form.get(name).invalid;
  }
}

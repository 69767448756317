import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StcikersService } from "app/services/stcikers.service";
import * as FilePond from "filepond";
import * as FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { UploadedFilesService } from "app/services/uploaded-files.service";
import { UsuariosService } from "app/services/usuarios.service";

export const CREATE_STICKER_MODAL_ID = "CREATE_STICKER_MODAL";

export interface CreateStickerData {
  id?: number;
}

FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileMetadata
);

@Component({
  selector: "app-create-sticker",
  templateUrl: "./create-sticker.component.html",
  styleUrls: ["./create-sticker.component.css"],
})
export class CreateStickerComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();
  @ViewChild("input") inputFile: ElementRef<HTMLInputElement>;

  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._stickers.getById(this.data.id)),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Formulario inválido";
      return this._stickers.create({ ...this.form.value });
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Formulario inválido";
      return this._stickers.update(this.data.id, this.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  //Status
  public loading: boolean;
  public requesting: boolean;
  public sections = ["general", "imágenes"];
  public section: "general" | "imágenes" = "general";
  public created: boolean;

  //Form
  public form: FormGroup;

  //Data
  public data: CreateStickerData;
  public sticker: any;

  //Files
  public file: FilePond.FilePond;

  constructor(
    private _stickers: StcikersService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _uploadedFiles: UploadedFilesService,
    private _usuarios: UsuariosService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initListeners();
    this.initFilePounds();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this.fb.group({
      nombre: [null, [Validators.required]],
      descripcion: [null, []],
      ecommerce: [false, [Validators.required]],
      es: [null, []],
      en: [null, []],
    });
  }

  initListeners() {
    this.onEcommerceChange();
  }

  onEcommerceChange() {
    this.form.controls["ecommerce"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        const { es, en } = this.form.controls;
        if (value) {
          es.setValidators([Validators.required]);
          en.setValidators([Validators.required]);
        } else {
          es.clearValidators();
          en.clearValidators();
        }
        es.updateValueAndValidity();
        en.updateValueAndValidity();
      });
  }

  getData() {
    this.data = this._modal.get(CREATE_STICKER_MODAL_ID).getData();
    if (!this.data.id) return;
    this.data$.subscribe(
      (data: any) => {
        this.form.patchValue(data);
        this.data = data;
        this.sticker = data;
        if (data.file)
          this.file.files = [
            {
              source: `${data.file.id}`,
              options: { type: "local" },
            },
          ];
      },
      () =>
        this._toast.errorToastr(
          "Error al obtener sticker, verifica tu conexión a internet."
        )
    );
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this._toast.successToastr("Sticker agregado correctamente.");
        this.sticker = data;
        this.data = data;
        this.created = true;
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    this.update$.subscribe(
      (data) => {
        this._toast.successToastr("Sticker editado correctamente.");
        this.sticker = data;
        this.data = data;
        this.created = true;
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(success?: boolean) {
    this._modal
      .get(CREATE_STICKER_MODAL_ID)
      .removeData()
      .setData({ success, created: this.created })
      .close();
    this._modal.removeModal(CREATE_STICKER_MODAL_ID);
  }

  //Files
  initFilePounds() {
    this.file = FilePond.create(this.inputFile.nativeElement, {
      allowMultiple: false,
      name: "files",
      server: {
        url: `${this._uploadedFiles.ROUTE}`,
        headers: {
          Authorization: `JWT ${this._usuarios.getToken()}`,
          "file-type": "stickers",
        },
        process: {
          url: "/",
          onload: (response) => {
            const [res] = JSON.parse(response);
            if (!res) return;
            this._stickers
              .update(this.sticker.id, { file: res["id"] })
              .subscribe(() => (this.sticker.file = res));
            return res["id"];
          },
        },
        revert: (id, load, error) => {
          this._uploadedFiles.delete(id, "stickers").subscribe(
            () => {
              this.sticker.file = null;
              load();
            },
            (err) => error(err)
          );
        },
        remove: (source, load, error) => {
          this._uploadedFiles.delete(source, "stickers").subscribe(
            () => {
              this.sticker.file = null;
              load();
            },
            (err) => error(err)
          );
        },
        load: { url: "/" },
      },
      acceptedFileTypes: ["image/*"],
    });
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).touched && this.form.get(name).invalid;
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let jwtoken = JSON.parse(localStorage.getItem('jwtToken'));
        if(jwtoken){

            const req = request.clone({
                setHeaders: {
                    Authorization: `JWT ${jwtoken.token}`
                }
            });
            return next.handle(req);
        } else {
          return next.handle(request);
        }
    }
}

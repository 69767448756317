import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PalletsService } from "app/services/pallets.service";
import { ProveedoresService } from "app/services/proveedores.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

export const CREATE_PALLET_MODAL = "CREATE_PALLET";

@Component({
  selector: "app-create-pallet",
  templateUrl: "./create-pallet.component.html",
  styleUrls: ["./create-pallet.component.css"],
})
export class CreatePalletComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  //status
  public loading: boolean;
  public requesting: boolean;

  //data
  public data: any;
  public proveedores: any[] = [];

  //form
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _pallets: PalletsService,
    private _proveedores: ProveedoresService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      nombre: [null, [Validators.required]],
      proveedor: [null, [Validators.required]],
    });
    this.getProveedores();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  //req data
  getData() {
    const { id } = this._modal.get(CREATE_PALLET_MODAL).getData();
    if (!id) return;
    this.loading = true;
    this._pallets
      .getById(id)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (data) => {
          this.data = data;
          this.form.patchValue(data);
        },
        (error) =>
          this._toast.errorToastr(
            "Error al obtener pallet, verifica tu conexión a internet."
          )
      );
  }

  getProveedores() {
    this._proveedores
      .getAll()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any[]) => {
          this.proveedores = data;
        },
        (error) =>
          this._toast.errorToastr(
            "Error al obtener proveedores, verifica tu conexión a internet."
          )
      );
  }

  //Manage Actions
  onSubmit() {
    if (this.form.invalid)
      return this._toast.errorToastr("Error en el formulario.");
    if (this.data) this.update();
    else this.create();
  }

  create() {
    this.requesting = true;
    this._pallets
      .create(this.form.value)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.requesting = false))
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Pallet creado correctamente.");
          this.close(data);
        },
        (error) =>
          this._toast.errorToastr("Error al crear pallet, intenta nuevamente.")
      );
  }

  update() {
    this.requesting = true;
    this._pallets
      .update(this.data.id, this.form.value)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.requesting = false))
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Pallet editado correctamente.");
          this.close(data);
        },
        (error) =>
          this._toast.errorToastr("Error al editar pallet, intenta nuevamente.")
      );
  }

  close(created?: any) {
    this._modal
      .get(CREATE_PALLET_MODAL)
      .removeData()
      .setData({ created })
      .close();
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).invalid && this.form.get(name).touched;
  }
}

import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";

import { SidebarModule } from "./shared/sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { JwtInterceptor } from "./http.interceptor";
import { ErrorInterceptor } from "./error.interceptor";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { Globals } from "./globals";

// no son de la plantilla
import { AngularTokenModule } from "angular-token";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ColorPickerModule } from "ngx-color-picker";
import { CreateArticuloComponent } from "./components/catalogos/articulos/components/create-articulo/create-articulo.component";
import { CreateArticuloModule } from "./components/catalogos/articulos/components/create-articulo/create-articulo.module";
import { CreateCategoriaArticuloModule } from "./components/catalogos/categorias-articulos/components/create-categoria-articulo/create-categoria-articulo.module";
import { CreateCategoriaArticuloComponent } from "./components/catalogos/categorias-articulos/components/create-categoria-articulo/create-categoria-articulo.component";
import { ConfirmationModalModule } from "./shared/modals/confirmation-modal/confirmation-modal.module";
import { ConfirmationModalComponent } from "./shared/modals/confirmation-modal/confirmation-modal.component";
import { CreateVarianteComponent } from "./components/catalogos/articulos/components/create-variante/create-variante.component";
import { CreateStickerComponent } from "./components/catalogos/stickers/components/create-sticker/create-sticker.component";
import { CreateStickerModule } from "./components/catalogos/stickers/components/create-sticker/create-sticker.module";
import { CreatePalletModule } from "./components/catalogos/pallets/components/create-pallet/create-pallet.module";
import { CreatePalletComponent } from "./components/catalogos/pallets/components/create-pallet/create-pallet.component";

const modalModules = [
  ConfirmationModalModule,
  CreateArticuloModule,
  CreateCategoriaArticuloModule,
  CreateStickerModule,
  CreatePalletModule,
];
const modalEntries = [
  ConfirmationModalComponent,
  CreateArticuloComponent,
  CreateCategoriaArticuloComponent,
  CreateVarianteComponent,
  CreateStickerComponent,
  CreatePalletComponent,
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule.forRoot(),
    HttpClientModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: "https://app.overflysports.com",
      apiPath: null,
      signInPath: "auth/login",
      signInRedirect: "/dashboard",
      signInStoredUrlStorageKey: "token_bba",
      signOutPath: "/",
      loginField: "usuario",
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    GooglePlaceModule,
    ColorPickerModule,
    ...modalModules,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [...modalEntries],
  providers: [
    Globals,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Tallas, Paises } from "../../create-articulo.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "create-articulo-general",
  templateUrl: "./create-articulo-general.component.html",
  styleUrls: ["./create-articulo-general.component.css"],
})
export class CreateArticuloGeneralComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();
  @Input() categorias: any[];

  @Input() set articulo(articulo: any) {
    this.form.patchValue({ ...articulo, categoria: articulo.categoria.id });
    if (articulo && articulo.colores) this.initColores(articulo.colores);
    this._articulo = articulo;
  }

  public _articulo: any;

  public form: FormGroup;

  public tallas = Object.keys(Tallas).map((key) => ({
    key,
    value: Tallas[key],
  }));

  public paises = Object.keys(Paises).map((key) => ({
    label: Paises[key],
    value: key,
  }));

  constructor(private fb: FormBuilder) {}

  get colores() {
    return this.form.get("colores") as FormArray;
  }

  get hasProps() {
    const tallas = (this.form.get("tallas") as FormArray).value;
    const colores = this.form.get("colores").value;
    return (tallas && tallas.length) || (colores && colores.length);
  }

  ngOnInit() {
    this.initForm();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {
    this.onIsOutletChanges();
  }

  onIsOutletChanges() {
    this.form.controls["isOutlet"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        const { outletPrice } = this.form.controls;
        if (value) outletPrice.setValidators([Validators.required]);
        else outletPrice.clearValidators();
        outletPrice.updateValueAndValidity();
      });
  }

  initForm() {
    this.form = this.fb.group({
      nombre: [null, [Validators.required]],
      categoria: [null, [Validators.required]],
      precio: [null, [Validators.required]],
      descuento: [null, []],
      stock: [null, []],
      descripcion: [null, []],
      informacion: [null, []],
      tallas: [[], []],
      colores: this.fb.array([]),
      pais: ["mx", [Validators.required]],
      isOutlet: [false, []],
      outletPrice: [null, []],
    });
  }

  initColores(colores: any[]) {
    this.form.controls["colores"] = this.fb.array(
      (colores || []).map((color) =>
        this.fb.group({
          id: [color.id],
          nombre: [color.nombre, [Validators.required]],
          color: [color.color, [Validators.required]],
        })
      )
    );
    this.form.updateValueAndValidity();
  }

  //Utils

  addColor() {
    this.colores.push(
      this.fb.group({
        id: [null],
        nombre: [null, [Validators.required]],
        color: [null, [Validators.required]],
      })
    );
  }

  removeColor(idx: number) {
    this.colores.removeAt(idx);
  }

  checkTalla(talla: Tallas) {
    return ((this.form.get("tallas").value as string[]) || []).some(
      (_talla) => talla === _talla
    );
  }

  changeTalla(talla: Tallas) {
    const tallasCtrl = this.form.get("tallas");
    if (this.checkTalla(talla))
      tallasCtrl.setValue(
        (tallasCtrl.value as string[]).filter((_talla) => _talla !== talla)
      );
    else tallasCtrl.setValue([...(tallasCtrl.value || []), talla]);
  }

  invalidControl(name: string) {
    return this.form.get(name).touched && this.form.get(name).invalid;
  }
}

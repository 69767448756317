import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./auth.guard";
import { NgxPermissionsGuard } from "ngx-permissions";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./components/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "ordenes",
        loadChildren: "./components/ordenes/ordenes.module#OrdenesModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "ORDENES",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "configuracion/configuracion-produccion",
        loadChildren:
          "./components/configuracion-produccion/configuracion-produccion.module#ConfiguracionProduccionModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "CONFIGURACION DE PRODUCCION",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "bitacora-inventario",
        loadChildren:
          "./components/inventario/inventario.module#InventarioModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "BITACORA DE INVENTARIO",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "bitacora-produccion",
        loadChildren:
          "./components/bitacora-produccion/bitacora-produccion.module#BitacoraProduccionModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "BITACORA DE PRODUCCION",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "inventario-detalle",
        loadChildren:
          "./components/inventario-detalle/inventario-detalle.module#InventarioDetalleModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "DETALLE DE INVENTARIO",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "facturas",
        loadChildren: "./components/facturas/facturas.module#FacturasModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "FACTURAS",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "suscripciones",
        loadChildren:
          "./components/suscripciones/suscripciones.module#SuscripcionesModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "SUSCRIPCIONES",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "inventario-productos",
        loadChildren:
          "./components/inventario-productos/inventario-productos.module#InventarioProductosModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "INVENTARIO DE PRODUCTOS",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "produccion-dia",
        loadChildren:
          "./components/produccion-dia/produccion-dia.module#ProduccionDiaModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "PRODUCCION DEL DIA",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "ecommerce",
        loadChildren: "./components/ecommerce/ecommerce.module#EcommerceModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "ECOMMERCE",
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "catalogos",
        loadChildren: "./components/catalogos/catalogos.module#CatalogosModule",
      },
      {
        path: "reportes",
        loadChildren: "./components/reportes/reportes.module#ReportesModule",
      },
    ],
  },
];

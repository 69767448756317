import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class UploadedFilesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  public ROUTE = `${this.globals.apiUrl}/uploaded-files`;

  public upload(data) {
    return this.http.post<any>(`${this.ROUTE}`, data);
  }

  public delete(id, type) {
    return this.http.delete<any>(`${this.ROUTE}/${id}?type=${type}`);
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class VariantesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private ROUTE = `${this.globals.apiUrl}/variantes`;

  getById(id: number) {
    return this.http.get<any>(`${this.ROUTE}/${id}`);
  }

  update(id: number, data: any) {
    return this.http.post<any>(`${this.ROUTE}/${id}`, data);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { UsuariosService } from "app/services/usuarios.service";

import * as FilePond from "filepond";
import * as FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import { UploadedFilesService } from "app/services/uploaded-files.service";
import { FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ArticulosService } from "app/services/articulos.service";

FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileMetadata
);

@Component({
  selector: "create-articulo-images",
  templateUrl: "./create-articulo-images.component.html",
  styleUrls: ["./create-articulo-images.component.css"],
})
export class CreateArticuloImagesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly onDestroy$ = new Subject<void>();

  @Input() articulo: any;

  public files: FilePond.FilePond[] = [];

  public imagenes: any[];

  public data: any = {};

  public colorCtrl = new FormControl(null);

  public get hasColores() {
    return (
      this.articulo && this.articulo.colores && this.articulo.colores.length
    );
  }

  constructor(
    private _usuario: UsuariosService,
    private _uploadedFiles: UploadedFilesService,
    private _articulos: ArticulosService
  ) {}

  get principal() {
    return (this.imagenes || []).find((image) => image.type == "principal");
  }

  get images() {
    return (this.imagenes || []).filter(({ type }) => type == "image");
  }

  get color() {
    return this.colorCtrl.value;
  }

  ngOnInit() {
    this.initImagenes();
  }

  ngAfterViewInit(): void {
    this.initFilePounds();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initFilePounds() {
    this.files = this.imagenes.map(({ id, imagenes, type, isColor }, index) =>
      FilePond.create(document.querySelector(`#image-${index}`), {
        allowMultiple: type == "image",
        name: "files",
        server: {
          url: `${this._uploadedFiles.ROUTE}`,
          headers: {
            Authorization: `JWT ${this._usuario.getToken()}`,
            "file-type": "articulos",
          },
          process: (
            fieldName,
            file,
            _,
            load,
            error,
            progress,
            abort,
            __,
            ___
          ) =>
            this.onProcess(
              fieldName,
              file,
              load,
              error,
              progress,
              abort,
              type,
              { [isColor ? "color" : "articulo"]: id }
            ),
          revert: this.FILES_CONFIG[type].revert,
          load: { url: "/" },
          remove: this.FILES_CONFIG[type].remove,
        },
        files: (imagenes || []).map(({ id }) => ({
          source: `${id}`,
          options: { type: "local" },
        })),

        acceptedFileTypes: ["image/*"],
      })
    );
  }

  initImagenes() {
    let imagenes = [this.articulo].map(({ id, imagen }) => ({
      id,
      imagenes: imagen ? [imagen] : [],
      type: "principal",
    }));
    this.imagenes = imagenes.concat(
      (this.hasColores ? this.articulo.colores : [this.articulo]).map(
        ({ id, imagenes = [] }) => ({
          id,
          imagenes,
          type: "image",
          isColor: !!this.hasColores,
        })
      )
    );
  }

  initListeners() {}

  removeImage(id) {
    if (this.hasColores) {
      const idx = this.articulo.colores.findIndex(
        ({ id }) => id == this.data.color
      );
      if (idx < 0) return;
      this.articulo.colores[idx].imagenes = this.articulo.colores[
        idx
      ].imagenes.filter(({ id: _id }) => _id != id);
    } else {
      this.articulo.imagenes = this.articulo.imagenes.filter(
        ({ id: _id }) => _id != id
      );
    }
  }

  addImage(image) {
    if (this.hasColores) {
      const idx = this.articulo.colores.findIndex(
        ({ id }) => id == this.data.color
      );
      if (idx < 0) return;
      this.articulo.colores[idx].imagenes = [
        image,
        ...this.articulo.colores[idx].imagenes,
      ];
    } else {
      this.articulo.imagenes = [image, ...this.articulo.imagenes];
    }
  }

  //

  onProcess(fieldName, file, load, error, progress, abort, type, data) {
    //Formdata
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append(fieldName, file, file.name);
    //Request
    const request = new XMLHttpRequest();
    request.open("POST", `${this._uploadedFiles.ROUTE}`);
    //Set headers
    request.setRequestHeader(
      "Authorization",
      `JWT ${this._usuario.getToken()}`
    );
    request.setRequestHeader("file-type", `articulos`);
    //Set progress
    request.upload.onprogress = (e) =>
      progress(e.lengthComputable, e.loaded, e.total);
    //Handle request
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        load(this.FILES_CONFIG[type].onload(request.response));
      } else error("Error on server.");
    };
    request.send(formData);
    //On abort
    return {
      abort: () => {
        request.abort();
        abort();
      },
    };
  }

  FILES_CONFIG = {
    image: {
      onload: (response) => {
        const [res] = JSON.parse(response);
        this.addImage(res);
        return res["id"];
      },
      revert: (id, load, error) => {
        this._uploadedFiles.delete(id, "articulos").subscribe(
          () => {
            this.removeImage(id);
            load();
          },
          (err) => error(err)
        );
      },
      remove: (source, load, error) => {
        this._uploadedFiles.delete(source, "articulos").subscribe(
          () => {
            this.removeImage(source);
            load();
          },
          (err) => error(err)
        );
      },
    },
    principal: {
      onload: (response) => {
        const [res] = JSON.parse(response);
        if (!res) return;
        this._articulos
          .update(this.articulo.id, { imagen: res["id"] })
          .subscribe(() => (this.articulo.imagen = res));
        return res["id"];
      },
      revert: (id, load, error) => {
        this._uploadedFiles.delete(id, "articulos").subscribe(
          () => {
            this.articulo.imagen = null;
            load();
          },
          (err) => error(err)
        );
      },
      remove: (source, load, error) => {
        this._uploadedFiles.delete(source, "articulos").subscribe(
          () => {
            this.articulo.imagen = null;
            load();
          },
          (err) => error(err)
        );
      },
    },
  };
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class CategoriasArticulosService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private ROUTE = `${this.globals.apiUrl}/categorias-articulos`;

  get(params?: { page?: number; limit?: number; search?: string }) {
    return this.http.get<[any[], number]>(this.ROUTE, {
      params: <any>params,
    });
  }

  getById(id: number) {
    return this.http.get<any>(`${this.ROUTE}/${id}`);
  }

  create(data: any) {
    return this.http.post<any>(this.ROUTE, data);
  }

  update(id: number, data: any) {
    return this.http.post<any>(`${this.ROUTE}/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.ROUTE}/${id}`);
  }
}

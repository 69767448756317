import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateCategoriaArticuloComponent } from "./create-categoria-articulo.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [CreateCategoriaArticuloComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxSmartModalModule],
})
export class CreateCategoriaArticuloModule {}

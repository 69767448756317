import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { VariantesService } from "app/services/variantes.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { switchMap, finalize, takeUntil, tap } from "rxjs/operators";
import { Tallas } from "../create-articulo/create-articulo.component";

export const CREATE_VARIANTE_MODAL_ID = "CREATE_VARIANTE_MODAL";

export interface CreateVarianteData {
  id?: number;
  color?: any;
  talla?: any;
}

@Component({
  selector: "create-variante",
  templateUrl: "./create-variante.component.html",
  styleUrls: ["./create-variante.component.css"],
})
export class CreateVarianteComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._variantes.getById(this.data.id)),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Error en el formulario.";
      throw "Cooming soon.";
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Error en el formulario.";
      return this._variantes.update(this.data.id, this.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  public data: CreateVarianteData;

  //Status
  public loading: boolean;
  public requesting: boolean;

  //Forms
  public form: FormGroup;

  public tallas = Object.keys(Tallas).map((key) => ({
    key,
    value: Tallas[key],
  }));

  constructor(
    private _variantes: VariantesService,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
    this.initListeners();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {
    this.onIsOutletChanges();
  }

  onIsOutletChanges() {
    this.form.controls["isOutlet"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        const { outletPrice } = this.form.controls;
        if (value) outletPrice.setValidators([Validators.required]);
        else outletPrice.clearValidators();
        outletPrice.updateValueAndValidity();
      });
  }

  initForm() {
    this.form = this.fb.group({
      precio: ["", [Validators.required]],
      stock: ["", []],
      descuento: ["", []],
      isOutlet: [false, []],
      outletPrice: [null, []],
    });
  }

  getData() {
    this.data = this._modal.get(CREATE_VARIANTE_MODAL_ID).getData();
    if (!this.data.id) return;
    this.data$.subscribe(
      (data) => {
        this.form.patchValue(data);
        this.data = data;
      },
      () =>
        this._toast.errorToastr(
          "Error al obtener variante, verifica tu conexión a internet."
        )
    );
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this._toast.successToastr("Variante agregada correctamente.");
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    this.update$.subscribe(
      (data) => {
        this._toast.successToastr("Variante editada correctamente.");
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(success?: boolean) {
    this._modal
      .get(CREATE_VARIANTE_MODAL_ID)
      .removeData()
      .setData({ success })
      .close();
    this._modal.removeModal(CREATE_VARIANTE_MODAL_ID);
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).touched && this.form.get(name).invalid;
  }
  getTalla(talla: Tallas) {
    return this.tallas.find(({ value }) => value === talla) || {};
  }
}
